<template>
  <div>
    <v-toolbar
      class="d-flex white justify-center custom-header"
      elevation="0"
      height="83"
    ><h3 class="green--text text--darken-4">{{ $t("messages.messageCenter") }}</h3></v-toolbar
    >
    <ApiErrorMessage :message="apiErrorMessage" />
    <v-container fluid class="pa-0">
      <template>
        <v-toolbar color="white" elevation="0" class="bt-top less-pd">
          <template class="pa-0">
            <v-select
              class="custom-select"
              height="40"
              :label="$t('messages.sortBy')"
              :items="['Received', 'Sent']"
              v-model="filterBy"
              dense
              solo
              elevation="0"
            ></v-select>
          </template>
          <v-spacer></v-spacer>
          <v-text-field
            class="custom-search"
            height="40"
            elevation="0"
            v-model="search"
            append-icon="mdi-magnify"
            dense
            solo
            :label="$t('messages.searchWords')"
          ></v-text-field>
        </v-toolbar>
      </template>
      <div class="custom-card">
        <div class="custom-box">
          <v-data-table
            :header-props="{sortIcon: null}"
            :headers="headers"
            :items="postThreads"
            :search="search"
            height="calc(100vh - 454px)"
            fixed-header
            class="color-green"
          >
            <template v-slot:item.action="{ item }">
              <v-btn
                @click="$router.push(`/dashboard/post-thread/${item.id}/messages`)"
                small
                color="green darkan-4 green-gradient white--text"
                class="text-capitalize"
              >{{ $t("messages.view")}}
              </v-btn>
            </template>
          </v-data-table>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ApiErrorMessage: () => import("@/components/common/ApiErrorMessage")
  },
  data() {
    return {
      search: "",
      headers: [
        {text: "Time & Date", align: "left", value: "latest_message.sent_at"},
        {text: "From", align: "left", value: "owner.name"},
        {text: "To", align: "left", value: "recipient.name"},
        {text: "Message", align: "left", value: "post.message"},
        {text: "Action", align: "left", value: "action"},
      ],
      filterBy: null,
      apiErrorMessage: null
    };
  },
  computed: {
    ...mapGetters({
      postThreads: "postThread/getPostThreads",
    }),
    filteredItems() {
      return this.food.filter((i) => {
        return !this.filterBy || i.type === this.filterBy;
      });
    },
  },

  async mounted() {
    this.$store.commit("setLoader", true);
    try {
      await this.getPostThreads();
    } catch ({ message }) {
      this.apiErrorMessage = message;
    } finally {
      this.$store.commit("setLoader", false);
    }
  },

  methods: {
    ...mapActions({
      getPostThreads: "postThread/getPostThreads",
    }),
  },
};
</script>
